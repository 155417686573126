import type { SerializedStyles } from "@emotion/react";
import type { HTMLProps, ReactNode } from "react";

import { Box } from "../Box";
import { Checkbox } from "../Checkbox";
import { Radio } from "../Radio";

import * as styles from "./DeprecatedSelectableTile.styles";

interface Props extends HTMLProps<HTMLInputElement> {
  /**
   * Triggers the checked attribute on the checkbox or radio button
   */
  checked: boolean;
  /**
   * Displays a radio button instead of a checkbox
   *
   * @default false
   */
  isRadio?: boolean;
  /**
   * Affects the padding of any content passed into SelectableTile
   */
  contentWrapperStyles?: SerializedStyles;
  /**
   * Customizes the component's Box styles.
   * Only takes into effect when the checked prop is false
   */
  boxStyle?: SerializedStyles;
  /**
   * Centres the checkbox or radio button instead of being right-aligned by default
   *
   * @default false
   */
  isCheckboxCentered?: boolean;
  /**
   * Aligns the checkbox/radio to the left of the content
   *
   * @default false
   */
  isInline?: boolean;
  /**
   * Specifies the location of the checkbox/radio when using the inline version
   *
   * @default "baseline"
   */
  inlineLocation?: "baseline" | "center";
  /**
   * Removes hardcoded height and inherits from content
   *
   * @default false
   */
  isContentHeight?: boolean;
  /**
   * Configures checkbox/radio container to take 100% height of its parent
   *
   * @default false
   */
  isFullHeight?: boolean;
  /**
   * Disable checkbox/radio and apply disabled styles
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Content to render inside the checkable tile
   *
   * @default undefined
   */
  children?: ReactNode;
  "aria-label": string;
}

const DeprecatedSelectableTile = ({
  checked,
  disabled = false,
  children,
  isRadio = false,
  contentWrapperStyles,
  boxStyle,
  isInline,
  inlineLocation = "baseline",
  isContentHeight,
  isFullHeight = false,
  isCheckboxCentered,
  ...rest
}: Props) => {
  const buildWrapperStyles = () => {
    return [
      styles.wrapper.default,
      !isRadio && styles.wrapper.checkbox,
      isInline && styles.inlineInput,
      isCheckboxCentered && styles.checkboxCentered,
      isFullHeight && styles.fullHeight,
      disabled && styles.wrapper.disabled,
    ];
  };

  const boxStyles = [
    styles.box.base,
    isInline && styles.box.inline,
    isFullHeight && styles.fullHeight,
    boxStyle,
  ];

  const labelStyles = [
    styles.labelWrapper,
    isRadio && styles.radioLabelOverride,
    isInline && styles.inlineLabel[inlineLocation],
  ];

  const contentStyles = [
    styles.contentSection.base,
    isInline && styles.contentSection.inlineContent,
    !isContentHeight && !isFullHeight && styles.contentSection.heights,
    contentWrapperStyles,
  ];

  const renderCheckbox = () => {
    if (isRadio) {
      return (
        <Radio checked={checked} disabled={disabled} labelStyle={labelStyles} {...rest}>
          <div css={contentStyles}>{children}</div>
        </Radio>
      );
    }

    return (
      <Checkbox checked={checked} disabled={disabled} labelStyle={labelStyles} {...rest}>
        <div css={contentStyles}>{children}</div>
      </Checkbox>
    );
  };

  const boxType = () => {
    if (checked) {
      return (
        <Box
          padding="none"
          isColor="system"
          layoutStyles={[
            isFullHeight && styles.fullHeight,
            boxStyle,
            styles.box.selected,
            disabled && styles.box.disabled,
          ]}
        >
          {renderCheckbox()}
        </Box>
      );
    }

    return (
      <Box padding="none" layoutStyles={boxStyles}>
        {renderCheckbox()}
      </Box>
    );
  };

  return <div css={buildWrapperStyles()}>{boxType()}</div>;
};

export { DeprecatedSelectableTile };
